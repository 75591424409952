import MediumEditor from 'medium-editor';
import rangy from 'rangy';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { SHOW } from 'src/components/DisplayWrapper';
import MediumEditorWrapper from 'src/components/MediumEditorWrapper';
import { sendSignalsAction } from 'src/redux/actions';
import { changeViewStateAction } from 'src/redux/report/actions';
import { COMMENTS_TARGET_ID } from 'src/redux/report/constants';
import { showNotification } from 'src/utils';

import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-selectionsaverestore';
import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';

export const AnchorModal = ({
  performAnchorAddingRequest,
  sectionId,
  text,
  isExtra,
  narrativeId = null,
}) => {
  rangy.init();

  const completedDraftComment = useSelector(state =>
    state.viewState?.draftComment?.isCompleted
      ? state.viewState?.draftComment
      : undefined,
  );

  const dispatch = useDispatch();

  const editorRef = useRef();

  useEffect(() => {
    if (
      completedDraftComment &&
      isExtra === completedDraftComment?.isExtra &&
      (narrativeId === null ||
        narrativeId === completedDraftComment?.narrativeId)
    ) {
      const { selection, title, anchorID, content } = completedDraftComment;

      const mediumInstance = editorRef.current;

      mediumInstance.importSelection(selection, false);

      const classApplier = rangy.createClassApplier('comment-anchor', {
        elementTagName: 'a',
        normalize: true,
        elementProperties: {
          id: anchorID,
          name: title,
          style: `'background-color': "#e3825b"; 'color': '#F9B000'`,
        },
      });

      classApplier.toggleSelection();

      try {
        mediumInstance.checkContentChanged();

        window.getSelection().removeAllRanges();

        if (narrativeId) {
          performAnchorAddingRequest(
            mediumInstance.getContent(),
            title,
            anchorID,
            content,
            narrativeId,
          );
        } else {
          performAnchorAddingRequest(
            mediumInstance.getContent(),
            title,
            anchorID,
            content,
          );
        }
      } catch (e) {
        console.log('error while checkContentChanged', e);
        showNotification(
          'Error while creating the comment. Please retry',
          'error',
        );
        dispatch(changeViewStateAction({ draftComment: undefined }));
      }
    }
  }, [completedDraftComment, editorRef, narrativeId]);

  // Check if descendant elemnts contains an anchor
  function childContainsLink(selection) {
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const container = document.createElement('div');
      container.appendChild(range.cloneContents());

      const links = container.getElementsByTagName('a');
      return links.length > 0;
    }

    return false;
  }

  function containsLinks(range) {
    const selection = window.getSelection();

    console.log('range', range);

    if (
      range?.startContainer?.nodeName?.toLowerCase() === 'a' ||
      range?.endContainer?.nodeName?.toLowerCase() === 'a' ||
      MediumEditor.util.getClosestTag(
        MediumEditor.selection.getSelectedParentElement(range),
        'a',
      )
    ) {
      return true;
    }

    if (childContainsLink(selection)) return true;

    return false;
  }

  const HighlighterButton = MediumEditor.extensions.anchor.extend({
    name: 'highlighter',
    tagNames: ['a'], // nodeName which indicates the button should be 'active' when isAlreadyApplied() is called
    contentDefault: '<b>Create comment</b>', // default innerHTML of the button,
    placeholderText: 'Input comment title',
    isAlreadyApplied: function () {
      const range = MediumEditor.selection.getSelectionRange(this.document);
      return containsLinks(range);
    },
    isActive: function () {
      return this.button.classList.contains('medium-editor-button-active');
    },
    setActive: function () {
      this.button.textContent = 'This text has already been highlighted';
      this.button.classList.add('medium-editor-button-active');
      this.button.classList.add('anchor-warning');
    },
    setInactive: function () {
      this.button.textContent = 'Create comment';
      this.button.classList.remove('medium-editor-button-active');
      this.button.classList.remove('anchor-warning');
    },
    completeFormSave: function (opts) {
      if (!opts?.value) return;

      this.base.restoreSelection();

      if (this.action) {
        if (this.getEditorOption('disableEditing')) {
          const name = opts.value.replaceAll('"', "''");

          let anchorID = `${sectionId}-highlight-${uuidv4()}`;

          if (narrativeId) {
            anchorID = `${narrativeId}-highlight-${uuidv4()}`;
          }

          const savedSel = this.base.exportSelection();

          const draftComment = {
            title: name,
            content: '',
            anchorID,
            selection: savedSel,
            isCompleted: false,
            isExtra,
          };

          if (narrativeId) {
            draftComment.narrativeId = narrativeId;
          }

          dispatch(
            changeViewStateAction({
              draftComment,
            }),
          );

          dispatch(
            sendSignalsAction({ [COMMENTS_TARGET_ID]: { [SHOW]: true } }),
          );

          window.getSelection().removeAllRanges();

          this.button.classList.remove('medium-editor-button-active');
          this.button.classList.remove('anchor-warning');
        } else {
          this.execAction(this.action, opts);
        }
      }
      this.base.checkSelection();
    },
    handleClick: function (event) {
      event.preventDefault();
      event.stopPropagation();

      const range = MediumEditor.selection.getSelectionRange(this.document);
      const linksPresent = containsLinks(range);

      if (linksPresent) return false;

      if (!this.isDisplayed()) {
        this.showForm();
        return true;
      }

      return false;
    },
  });

  const CustomAnchorPreview = MediumEditor.extensions.anchorPreview.extend({
    name: 'customAnchorPreview',
    showPreview: function (event) {
      const highlightComment = event.getAttribute('id');
      if (highlightComment) {
        dispatch(
          changeViewStateAction({
            highlightComment,
          }),
        );
      }
    },
  });

  return (
    <MediumEditorWrapper
      text={text || ''}
      editorRef={editorRef}
      options={{
        anchorPreview: false,
        placeholder: {
          text: '',
        },
        disableEditing: true,
        toolbar: {
          buttons: ['highlighter'],
        },
        extensions: {
          highlighter: new HighlighterButton(),
          customAnchorPreview: new CustomAnchorPreview(),
        },
      }}
    />
  );
};

export default AnchorModal;
