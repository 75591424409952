import { Editor } from '@tinymce/tinymce-react';
import React, { FC, useRef } from 'react';

interface Props {
  content: string;
  onChange: (value: string) => void;
}

const TinyEditorWrapper: FC<Props> = ({ content, onChange }) => {
  const editorRef = useRef(null);

  function removeLinks(updatedContent: string) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = updatedContent;

    tempDiv.querySelectorAll('a').forEach(link => {
      if (!link.classList.contains('comment-anchor')) {
        const textNode = document.createTextNode(link.textContent);
        link.parentNode.replaceChild(textNode, link);
      }
    });

    return tempDiv.innerHTML;
  }

  return (
    <>
      <Editor
        tinymceScriptSrc={`${process.env.REACT_APP_DOMAIN}/tinymce/tinymce.min.js`}
        onInit={(evt, editor) => {
          editorRef.current = editor;

          editor.on('BeforeSetContent', function (e) {
            e.content = e.content.replace(/<!--[\s\S]*?-->/g, '');
            e.content = removeLinks(e.content);
          });

          editor.on('PostProcess', function (e) {
            if (e.set) {
              e.content = e.content.replace(/<!--[\s\S]*?-->/g, '');
              e.content = removeLinks(e.content);
            }
          });
        }}
        onEditorChange={onChange}
        value={content}
        init={{
          height: 500,
          menubar: false,
          plugins: 'lists',
          branding: false,
          allow_html_in_named_anchor: true,
          toolbar1: 'cut copy paste | undo redo',
          toolbar2:
            'bold italic underline strikethrough superscript | removeformat | numlist bullist | indent outdent',
          browser_spellcheck: true,
          remove_trailing_brs: false,
          valid_elements: `-a[id|name|class|style],-strong/b,-em/i,-sup,-strike,-u,#p,-ul[type|compact],-ol[reversed|start|type|compact],#li[value|type],-s,br[clear],-span`,
          allow_conditional_comments: false,
        }}
      />
    </>
  );
};

export default TinyEditorWrapper;
