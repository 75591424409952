import { iconInfoGlyph } from '@wfp/icons';
import { iconLocked, iconWarningSolid } from '@wfp/icons';
import { Modal } from '@wfp/ui';
import {
  Button,
  Icon,
  Module,
  ModuleBody,
  ModuleFooter,
  ModuleHeader,
  Tag,
} from '@wfp/ui';
import { format } from 'date-fns';
import * as DOMPurify from 'dompurify';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import ConfirmationModal from 'src/components/ConfirmationModal';
import AnchorModal from 'src/components/ReportTemplate/anchorModal';
import TinyEditorWrapper from 'src/components/ReportTemplate/WorkspaceACR2023/TinyEditorWrapper';
import VersionItem from 'src/components/ReportTemplate/WorkspaceACR2023/VersionItem';
import VersionsModal from 'src/components/ReportTemplate/WorkspaceACR2023/VersionsModal';
import {
  API_HOST,
  narrativeAutosaveInterval,
  SUCCESS_REQUEST,
  UPDATE,
} from 'src/constants';
import {
  cancelMultiNarrative,
  forceUnlockMultiNarrative,
  lockMultiNarrative,
  receiveMultiNarrativeSingleResponse,
  requestAPI,
  requestAPIHoldingData,
  resetMultiNarrative,
  restoreVersionMultiNarrative,
  saveMultiNarrative,
  sendSignalsAction,
} from 'src/redux/actions';
import { changeViewStateAction } from 'src/redux/report/actions';
import {
  COMMENTS_LIST,
  COMMENTS_TARGET_ID,
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import type { INarrative, ISelectedVersion } from 'src/types';
import { countWords, sendGAEvent, showNotification } from 'src/utils';

interface Props {
  narrative: INarrative;
  isEditEnable: boolean;
  isForcePostEnabled: boolean;
}

const Narrative: FC<Props> = ({
  narrative,
  isEditEnable,
  isForcePostEnabled,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showVersionsModal, setShowVersionsModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedVersion, setSelectedVersion] =
    useState<ISelectedVersion | null>(null);

  const [autosave, setAutosave] = useState(false);

  const viewState = useSelector((state: RootStateOrAny) => state.viewState);
  const reportDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY].data,
  );
  const sectionDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData?.[SECTION_DETAIL_DATA_KEY]?.data,
  );
  const reportDetailIsFetching = useSelector(
    (state: RootStateOrAny) =>
      state.viewData[REPORT_DETAIL_DATA_KEY].isFetching,
  );

  const dispatch = useDispatch();

  const {
    id,
    is_optional: isOptional,
    limit,
    title,
    i_am_editor: iAmEditor,
    content: initialContent,
    editor,
    locked_at: lockedAt,
    saved = false,
    autosaved = false,
    canceled = false,
    locked = false,
    info,
  } = narrative;

  const [content, setContent] = useState(initialContent || '');

  const wordsExceed = countWords(content) > limit;
  const narrativeEditedByOthers = !iAmEditor && editor !== null;
  const imEditing = isEdit || iAmEditor;
  const notPublished = reportDetailData?.state !== 'Published';

  useEffect(() => {
    if (iAmEditor) setIsEdit(true);
  }, [iAmEditor]);

  useEffect(() => {
    if (saved) {
      setIsEdit(false);
      dispatch(resetMultiNarrative({ narrativeId: id }));
    }
  }, [saved]);

  useEffect(() => {
    if (canceled) {
      setIsEdit(false);
      setContent(initialContent);
      dispatch(resetMultiNarrative({ narrativeId: id }));
    }
  }, [canceled]);

  useEffect(() => {
    if (autosaved) {
      showNotification(`Auto save applied for ${title}`, 'success', {
        autoClose: 3500,
      });
      dispatch(resetMultiNarrative({ narrativeId: id }));
    }
  }, [autosaved]);

  useEffect(() => {
    if (locked) {
      setIsEdit(true);
      dispatch(resetMultiNarrative({ narrativeId: id }));
    }
  }, [locked]);

  useEffect(() => {
    if (selectedVersion !== null) setShowVersionsModal(false);
  }, [selectedVersion]);

  useEffect(() => {
    if (!isEdit) {
      setContent(initialContent);
    }
  }, [initialContent, isEdit]);

  function handleCancel() {
    dispatch(
      cancelMultiNarrative({
        narrativeId: id,
        reportId: sectionDetailData?.report?.id,
      }),
    );
    setShowCancelModal(false);
  }

  function handleSave() {
    setShowSaveModal(false);
    dispatch(
      saveMultiNarrative({
        narrativeId: id,
        content,
        wordCount: countWords(content),
        autoSave: false,
        reportId: sectionDetailData?.report?.id,
      }),
    );
  }

  function handleRevertVersion() {
    dispatch(
      restoreVersionMultiNarrative({
        narrativeVersionId: selectedVersion.id,
        content: selectedVersion.cleanContent,
        narrativeId: id,
      }),
    );
    setSelectedVersion(null);
  }

  function handleEditBtn() {
    sendGAEvent('edit_narrative_btn_clicked');
    dispatch(
      lockMultiNarrative({
        narrativeId: id,
        reportId: sectionDetailData?.report?.id,
      }),
    );
  }

  function handleForceEditBtn() {
    sendGAEvent('force_post_narrative_btn_clicked');
    dispatch(
      forceUnlockMultiNarrative({
        narrativeId: id,
        reportId: sectionDetailData?.report?.id,
      }),
    );
  }

  function getWordsCountResume() {
    const wordsCount = countWords(content);

    return `${wordsCount || 0} / ${limit}`;
  }

  function createComment(
    anchorName: string,
    anchorID: number,
    anchorContent: string,
  ) {
    const params = {
      url_format: {
        _office_: sectionDetailData.report.office_code,
        _report_: sectionDetailData.report.id,
        _section_: viewState.activeSectionID,
      },
      method: 'post',
      data: {
        content: anchorContent,
        title: anchorName,
        anchor: anchorName,
        anchor_id: anchorID,
      },
      storage_key: `${COMMENTS_LIST}_created`,
      success_actions: [
        sendSignalsAction({
          [undefined as any]: {
            [UPDATE]: true,
            [SUCCESS_REQUEST]: true,
          },
        }),
        requestAPIHoldingData(REPORT_DETAIL_DATA_KEY, null, {
          url_format: { _id_: sectionDetailData.report.id },
        }),
      ],
    };

    dispatch(requestAPI(COMMENTS_LIST, null, params));
  }

  function onCommentAttachedToNarrative() {
    const requestKwargs = {
      url_format: {
        _office_: sectionDetailData.report.office_code,
        _report_: sectionDetailData.report.id,
        _section_: viewState.activeSectionID,
      },
      storage_key: COMMENTS_LIST,
    };
    dispatch(requestAPI(COMMENTS_LIST, null, requestKwargs));

    dispatch(
      sendSignalsAction({
        [COMMENTS_TARGET_ID]: { [UPDATE]: true },
      }),
    );
  }

  async function performAnchorAddingRequest(
    value: string,
    anchorName: string,
    anchorID: number,
    anchorComment: string,
    narrativeId?: number,
  ) {
    const url = `${API_HOST}/api/acr-sections/${viewState.activeSectionID}/add_anchors/`;
    const request_body = {
      content: value,
      editor: reportDetailData.username,
      narrative_id: narrativeId,
      title: anchorName,
    };

    try {
      const result = await apiCallAsync(url, request_body, 'post');

      // Update narrative content
      dispatch(receiveMultiNarrativeSingleResponse(result.data));

      createComment(anchorName, anchorID, anchorComment);

      const requestKwargs = {
        url_format: {
          _office_: sectionDetailData.report.office_code,
          _report_: sectionDetailData.report.id,
          _section_: viewState.activeSectionID,
        },
        storage_key: COMMENTS_LIST,
      };

      dispatch(requestAPI(COMMENTS_LIST, null, requestKwargs));

      onCommentAttachedToNarrative();
      dispatch(changeViewStateAction({ draftComment: undefined }));
    } catch (e) {
      const msg = e.response?.data?.error;
      if (!msg) return;

      showNotification(msg, 'error');
    }
  }

  function onNarrativeChange(value: string) {
    setContent(value);
    if (!autosave) setAutosave(true);
  }

  useEffect(() => {
    if (!autosave) {
      return;
    }

    const timer = setTimeout(() => {
      if (!isEdit) {
        return;
      }

      dispatch(
        saveMultiNarrative({
          narrativeId: id,
          content,
          wordCount: countWords(content),
          autoSave: true,
        }),
      );
    }, narrativeAutosaveInterval);

    return () => clearTimeout(timer);
  }, [autosave, content, isEdit]);

  const NarrativeErrorMessage = () => {
    return (
      <div className="narrative-footer narrative-error-msg">
        <Icon icon={iconWarningSolid} fill="#E4002B" description="" />
        <div>Word count limit exceed: {getWordsCountResume()}</div>
      </div>
    );
  };

  return (
    <>
      <Module
        className="multi-narrative-section"
        data-test-id={title.toLowerCase().split(' ').join('-')}
      >
        <ModuleHeader>
          <div className="multi-narrative-section-title">
            {title}
            {info && (
              <Icon
                className="word-limit-panel-icon-info"
                width="24"
                height="24"
                fill="#0b77c2"
                icon={iconInfoGlyph}
                description=""
                data-test-id="information-icon"
                onClick={() => setShowInfoModal(true)}
              />
            )}
            {isOptional && <Tag type="info">Optional</Tag>}
          </div>

          <div className="multi-narrative-section-btns">
            <Button
              onClick={() => {
                setAutosave(false);
                sendGAEvent('narrative_versions_btn_clicked');
                setShowVersionsModal(true);
              }}
              kind="ghost"
              data-test-id="versions-button"
            >
              Versions
            </Button>
            {isEdit && (
              <>
                <Button
                  onClick={() => {
                    setAutosave(false);
                    setShowCancelModal(true);
                  }}
                  kind="secondary"
                  data-test-id="cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setAutosave(false);
                    setShowSaveModal(true);
                  }}
                  disabled={wordsExceed || reportDetailIsFetching}
                  data-test-id="save-version-button"
                >
                  Save version
                </Button>
              </>
            )}
            {!isEdit &&
              !narrativeEditedByOthers &&
              isEditEnable &&
              notPublished && (
                <Button
                  data-test-id="edit-button"
                  onClick={handleEditBtn}
                  disabled={reportDetailIsFetching}
                >
                  Edit
                </Button>
              )}
            {!isEdit &&
              narrativeEditedByOthers &&
              notPublished &&
              isForcePostEnabled && (
                <Button
                  data-test-id="force-edit-button"
                  onClick={handleForceEditBtn}
                  disabled={reportDetailIsFetching}
                >
                  Force Edit
                </Button>
              )}
          </div>
        </ModuleHeader>
        <ModuleBody>
          {isEdit && (
            <TinyEditorWrapper content={content} onChange={onNarrativeChange} />
          )}
          {notPublished &&
            !isEdit &&
            !narrativeEditedByOthers &&
            reportDetailData?.permissions?.comment_permissions?.add_comment &&
            (!content ? (
              <div className="narrative-empty-info">No narrative</div>
            ) : (
              <AnchorModal
                sectionId={viewState.activeSectionID}
                text={content}
                performAnchorAddingRequest={performAnchorAddingRequest}
                isExtra={false}
                narrativeId={id}
              />
            ))}
          {!isEdit &&
            (!notPublished ||
              narrativeEditedByOthers ||
              !reportDetailData?.permissions?.comment_permissions
                ?.add_comment) &&
            (!content ? (
              <div className="narrative-empty-info">No narrative</div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(content),
                }}
              />
            ))}
        </ModuleBody>
        <ModuleFooter
          className={`${
            imEditing || narrativeEditedByOthers ? 'edit-mode' : ''
          }`}
        >
          {imEditing && lockedAt && (
            <div className="narrative-locked-msg">
              <Icon icon={iconLocked} fill="#04193B" description="" />
              Narrative locked by you on
              {` ${format(lockedAt, 'dd MMMM yyyy, HH:mm')} (CEST)`}. It is not
              editable by others.
            </div>
          )}
          {narrativeEditedByOthers && lockedAt && (
            <div className="narrative-locked-msg">
              <Icon icon={iconLocked} fill="#04193B" description="" />
              Narrative locked by {editor} on
              {` ${format(lockedAt, 'dd MMMM yyyy, HH:mm')} (CEST)`}. It is not
              editable by others.
            </div>
          )}
          {wordsExceed ? (
            <NarrativeErrorMessage />
          ) : (
            <div data-test-id="word-counter" className="narrative-footer">
              Word count: {getWordsCountResume()}
            </div>
          )}
        </ModuleFooter>
      </Module>
      {showVersionsModal && (
        <VersionsModal
          title={title}
          onClose={() => setShowVersionsModal(false)}
          narrativeId={id}
          setSelectedVersion={setSelectedVersion}
          revertEnabled={
            !narrativeEditedByOthers && isEditEnable && !isEdit && notPublished
          }
        />
      )}
      {selectedVersion && (
        <ConfirmationModal
          open={true}
          onSubmit={handleRevertVersion}
          onClose={() => setSelectedVersion(null)}
          heading={`Revert to this version for ${title}?`}
          content={
            <>
              <div className="revert-version-subtitle">
                Are you sure you want to revert the narrative to this version?
              </div>
              <VersionItem
                content={selectedVersion.content}
                cleanContent={selectedVersion.cleanContent}
                editor={selectedVersion.editor}
                date={selectedVersion.date}
                id={selectedVersion.id}
              />
            </>
          }
          primaryButtonText="Yes"
          secondaryButtonText="No"
          wide
          customClassName="modal-revert-version"
        />
      )}
      {showSaveModal && (
        <ConfirmationModal
          open={true}
          onSubmit={handleSave}
          onClose={() => setShowSaveModal(false)}
          heading={`Save as a new version for ${title}?`}
          content={
            <>
              Are you sure you want to save your changes as a new version of
              this narrative?
            </>
          }
          primaryButtonText="Yes"
          secondaryButtonText="No"
        />
      )}
      {showCancelModal && (
        <ConfirmationModal
          open={true}
          onSubmit={handleCancel}
          onClose={() => setShowCancelModal(false)}
          heading={`Discard changes for ${title}?`}
          content={
            <>
              Are you sure you want to cancel your changes and go back to the
              previous version of this narrative?
            </>
          }
          primaryButtonText="Yes"
          secondaryButtonText="No"
        />
      )}
      <Modal
        open={showInfoModal}
        onRequestClose={() => setShowInfoModal(false)}
        modalHeading={title}
        passiveModal
        iconDescription="Close the modal"
        className="info-text-modal"
      >
        <div dangerouslySetInnerHTML={{ __html: info }} />
      </Modal>
    </>
  );
};

export default Narrative;
